body {
    color: #000 !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul {
        margin: 0;
        padding: 0;
    }

    a {
        cursor: pointer !important;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .right-10 {
        right: 10px;
    }

    .pl-0 {
        padding-left: 0 !important;
    }

    .pr-0 {
        padding-right: 0 !important;
    }

    .w-50 {
        width: 50%;
    }

    .bg-opacity-10 {
        --bs-bg-opacity: 0.10;
    }

    .text-left {
        text-align: left !important;
    }

    .text-right {
        text-align: right !important;
    }

    img {
        object-fit: cover;

        &.object-fit-contain {
            object-fit: contain;
        }
    }

    .divider {
        span {
            color: #000000;
            font-weight: 600;
        }
    }

    .form-control,
    .form-select {
        &:focus {
            border-color: #424242;
        }
    }

    .btn-icon {
        width: 30px;
        height: 30px;
    }

    .btn-outline-success:hover,
    .btn-outline-secondary:hover {
        color: #ffffff;
    }

    .btn-outline-success {
        &:focus {
            background-color: #0cb785;
        }
    }

    .btn-outline-danger {
        &:focus {
            background-color: #dc3545;
        }
    }

    .btn:first-child {

        &:focus,
        &:active {
            color: #ffffff;
        }
    }

    .btn-outline-secondary:focus {
        color: #6e7985;
    }

    .react-select-container {
        z-index: 99;
    }

    .offcanvas-backdrop {
        z-index: 1045;
    }

    .top-header {
        top: 72px;
        z-index: 9;
        padding: 10px 0;
        position: sticky;
    }

    table {
        color: #000 !important;
    }

    table thead tr th,
    table tbody tr td {
        vertical-align: middle;
    }

    // TWO THREE LINE ELLIPSIS
    .two-line-ellipsis,
    .three-line-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    .two-line-ellipsis {
        -webkit-line-clamp: 2;
    }

    .three-line-ellipsis {
        -webkit-line-clamp: 3;
    }

    // CARD ONE DISHES CARD
    .card-one.dishes-card {
        .card-body {

            .top-badge {
                position: absolute;
                left: 15px;
                top: 10px;
            }

            .dish-img {
                width: 70px;
                height: 70px;
                overflow: hidden;
                border-radius: 50%;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .dish-title {
                font-size: 14px;
                font-weight: 600;
                margin: 5px 0;
                line-height: 20px;
                margin-top: 15px;
            }
        }
    }

    .custom-search-input {
        &.form-search {
            border: 1px solid #e3e2e2;
            box-shadow: none;
            i {
                color: #a9a9a9;
            }
        }
    }


    // OFF CANVAS CSS BEGIN
    .offcanvas-header {
        border-bottom: 1px solid rgb(204, 210, 218);
    }

    .offcanvas.offcanvas-end {
        background-color: #ffffff;

        &.off-canvas-custom-width {
            width: 45%;

            .offcanvas-header {
                height: 72px;
                padding: 1rem 1.5rem;
            }

            .offcanvas-body {
                padding: 1rem 1.5rem;
            }
        }

        .custom-offcanvas-header {
            .btn-close {
                position: absolute;
                top: 20px;
                right: 20px;
            }
        }

        .offcanvas-footer {
            display: flex;
            align-items: center;
            position: sticky;
            bottom: 0;
            left: 0;
            right: 0;
            height: 70px;
            padding: 1rem 1.5rem;
            justify-content: space-between;

            box-shadow: 0 1px 1px rgba(33, 40, 48, .01), 0px -1px 4px rgba(33, 40, 48, .01), 0 16px 16px rgba(33, 40, 48, .01);
        }
    }

    .offcanvas-footer {
        border-top: 1px solid rgb(204, 210, 218);
    }

    // OFF CANVAS FOOTER

    .outline-bttn-line {
        color: #1B00FF;
        border: 1px solid #1B00FF;
        background-color: transparent;
        border-radius: 25px;
        padding: 4px 14px;

        &:hover {
            color: #ffffff;
            background-color: #1B00FF;
        }
    }

    // INDICATOR
    .indicator {
        width: 18px;
        height: 18px;
        border: 1px solid;
        position: relative;
        background-color: transparent;

        &::after {
            content: "";
            width: 8px;
            height: 8px;
            top: 50%;
            left: 50%;
            position: absolute;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
        }

        &.veg {
            border-color: #12CF2A;

            &::after {
                background-color: #12CF2A;
            }
        }

        &.non-veg {
            border-color: #DC3545;

            &::after {
                background-color: #DC3545;
            }
        }

        &.eggiterian {
            border-color: #f9e10c;

            &::after {
                background-color: #f9e10c;
            }
        }

        &.not-applicable {
            border-color: #d2d2d2;

            &::after {
                background-color: #d2d2d2;
            }
        }

    }

    // QUANTITY
    .qty {
        .count {
            color: #000;
            display: inline-block;
            vertical-align: top;
            font-size: 16px;
            font-weight: 600;
            line-height: 25px;
            padding: 0 2px;
            min-width: 35px;
            text-align: center;
            background-color: transparent;
        }

        .btn.plus,
        .btn.minus {
            width: 25px;
            height: 25px;
            cursor: pointer;
            display: inline-block;
            vertical-align: top;
            border-radius: 50%;
            text-align: center;
            padding: 0;

            i {
                font-size: 16px;
            }

            &:focus {
                color: #161616;
            }

            &:hover,
            &:active {
                color: #fff;
            }
        }

        input {
            border: 0;
            width: 2%;
        }

        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input:disabled {
            background-color: white;
        }
    }

    // CUSTOM COMMON TABLE
    .custom-common-table {
        .cviWVp {
            padding: 0.5rem;
        }

        .ewqyob {
            max-width: fit-content;
            min-width: auto;
        }

        .jJhCqB div:first-child {
            white-space: normal;
        }
    }

    .paid-leave-bg {
        background-color: #d7d162 !important;
    }

    .unpaid-leave-bg {
        background-color: #c56234 !important;
    }

    .present-bg {
        background-color: #338b45 !important;
    }

    .absent-bg {
        background-color: #cd4202 !important;
    }

    .halfDay-leave-bg {
        background-color: #f1f1f1 !important;
    }

    .work-off-bg {
        background-color: #c1c1c1 !important;
    }

    // VEG NONVEG STATUS
    .veg-nonveg-status {
        display: inline-block;
        width: 15px;
        height: 15px;
        position: relative;
        border: 1px solid transparent;

        &::after {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
        }

        &.veg {
            border-color: #00A428;

            &::after {
                background-color: #00A428;
            }
        }

        &.non-veg {
            border-color: #FF1D1D;

            &::after {
                background-color: #FF1D1D;
            }
        }

        &.not-applicable {
            border-color: #d2d2d2;

            &::after {
                background-color: #d2d2d2;
            }
        }
    }

    // DANGER RADIO STYLE
    .danger-radio-style {
        label {
            cursor: pointer;
        }

        .form-check-input {
            width: 1.2em;
            height: 1.2em;
            margin-right: 10px;

            &:checked {
                background-color: #dc3545;
                border-color: #dc3545;
            }
        }
    }

    // SUB MENU COMMON TABS
    .page-in-tabs {
        .nav-pills {
            display: block;

            .nav-item {
                width: 100%;
                margin-bottom: 5px;

                .nav-link {
                    width: 100%;
                    padding: 10px;
                    font-weight: 500;
                    background-color: transparent;

                    &.active {
                        color: #506fd9;
                        background-color: rgb(80 111 217 / 10%);
                    }
                }
            }
        }
    }

    // SCROLLBAR CONTAINER
    .scrollbar-container {
        .nav-pills {
            .nav-item {
                .nav-link {
                    display: flex;
                    color: #000000;
                    font-weight: 500;
                    align-items: center;

                    i {
                        margin-right: 12px;
                        line-height: 1;
                        font-size: 20px;
                        width: 18px;
                        opacity: 0.85;
                    }

                    &.active {
                        color: #506fd9;
                        background-color: rgb(80 111 217 / 10%);
                    }
                }
            }
        }
    }

    // PERMISSION STATUS
    .permission-status {
        .react-checkbox-tree {
            ol {
                padding-left: 0;

                li {
                    ol {
                        padding-left: 0;

                        li {
                            padding-left: 30px;
                        }
                    }
                }
            }

            .rct-node-parent {
                margin-bottom: 20px;
                position: relative;
                padding: 10px 0;
                background-color: #fff;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

                li {
                    padding: 5px 30px;
                }
            }

            .rct-node {
                display: block;
            }

            .rct-text {
                .rct-collapse-btn {
                    position: absolute;
                    right: 10px;
                    top: 15px;
                    border: 0;
                    box-shadow: none;
                    line-height: normal;
                    background-color: transparent;

                    span {
                        font-size: 24px;
                    }
                }
            }
        }

        label {
            display: flex;
            padding: 5px 30px;
            font-size: 14px;
            cursor: pointer;
            align-items: center;

            input {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }

            &:first-child {
                padding-left: 10px;
            }
        }

        input {
            cursor: pointer;
        }

        .common-list-group {
            label {
                padding-left: 10px;
            }
        }
    }

    .jJhCqB div:first-child {
        white-space: normal
    }

    // FONT STYLE BTTNS
    .font-style-bttns {
        cursor: pointer;
        margin-right: 10px;

        input {
            display: none;

            &:checked {
                &+span {
                    color: #ffffff;
                    background-color: #3E3E3E;
                }
            }
        }

        span {
            width: 30px;
            height: 30px;
            border-radius: 5px;

            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ECECEC;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    // EDIT IMAGE
    .edit-image {
        font-size: 24px;
        position: absolute;
        z-index: 2;
        right: -10px;
        top: -10px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // CUSTOMER ADDRESS
    .customer-address {
        align-items: center;
        border: 1px solid #ccc;
        margin: 0;
        border-radius: 3px;
        padding: 10px 0;

        .default-address {
            color: #dc3545;
            border-radius: 5px;
            border: 1px solid #dc3545;
            padding: 4px 6px;
            font-size: 12px;
        }
    }

    // ADDRESS CUSTOM RADIO BUTTON
    .address-custom-radio-bttm {
        display: flex;
        align-items: center;

        .form-row {
            background: #ffffff;
            border-radius: 8px;
            height: 30px;
            overflow: hidden;
            position: relative;
            width: 90px;
            border: 1px solid #ccc;
            display: flex;
            align-items: center;

            .form-input {
                -webkit-appearance: none;
                appearance: none;

                &::before {
                    content: '';
                    cursor: pointer;
                    height: 100%;
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 100%;
                }

                &:checked::before {
                    border-color: #dc3545;
                }

                &::after {
                    content: '';
                    cursor: pointer;
                    height: 15px;
                    border-radius: 50px;
                    border: 5px solid #ccc;
                    left: 10%;
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    -webkit-transform: translate(0, -50%);
                    width: 15px;
                }

                &:checked::after {
                    border-color: #dc3545;
                }

                &:checked~.form-label {
                    color: #dc3545;
                }
            }

            .form-label {
                z-index: 1;
                position: relative;
                width: 100%;
                text-align: center;
                padding-left: 15px;
                margin: 0;
                cursor: pointer;
            }
        }
    }

    // CUSTOME SCROLL BAR
    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-track {
        background: #ddd;
    }

    ::-webkit-scrollbar-thumb {
        background: #808080;
    }

    // DELETED ADDONS
    .deleted-addons {
        color: #dc3545 !important;
        text-decoration: line-through;
    }

    // DELETED ITEMS
    .deleted-items {
        color: #dc3545 !important;
        background-color: #FFEAEA;
        border: solid 1px #dc3545 !important;
    }

    // COMMON CUSTOM TAB
    .common-custom-tabs {
        background-color: transparent;

        .nav-item {
            .nav-link {
                color: #6e7985;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0;
                position: relative;
                text-transform: capitalize;
                border-bottom: 1px solid transparent;
                background-color: transparent;

                .badge-length-web {
                    color: #ffffff;
                    margin-left: 5px;
                    background-color: #6e7985;
                }

                i {
                    font-size: 22px;
                }

                &:hover,
                &.active {
                    color: #506fd9;
                    border-bottom: 2px solid #506fd9;
                    background-color: transparent;

                    .badge-length-web {
                        color: #ffffff;
                        background-color: #506fd9;
                    }
                }
            }
        }

        .custom-tab-body {
            margin-top: 20px;
        }
    }
}


[data-skin=dark]:not([data-sidebar=prime]) {
    .manage-item-page {
        .custom-tab-body {
            .price-variant-bg {
                background-color: #141926;
            }
        }
    }
}

.ag-root-wrapper-body.ag-layout-normal {
    height: auto !important;
    min-height: auto !important;
}

// VIEW PORT TABLET VIEW MEDIA QUERY
@media only screen and (max-width: 820px) {
    .offcanvas.offcanvas-end {
        &.off-canvas-custom-width {
            width: 60%;
        }
    }
}

// TABLET VIEW MEDIA QUERY
@media only screen and (max-width: 768px) {
    .offcanvas.offcanvas-end {
        &.off-canvas-custom-width {
            width: 80%;
            padding: 1rem;
        }
    }
}

// MOBILE VIEW MEDIA QUERY
@media only screen and (max-width: 480px) {
    .sm-mt-2 {
        margin-top: 10px !important;
    }

    .sm-d-none {
        display: none !important;
    }

    .sm-p-0 {
        padding: 0 !important;
    }

    .sm-pl-0 {
        padding-left: 0 !important;
    }

    .offcanvas.offcanvas-end {
        &.off-canvas-custom-width {
            width: 95%;
        }
    }
}