.border-left-after {
    position: relative;
    &:after {
        content: "";
        width: 2px;
        top: 2px;
        bottom: 2px;
        right: -8px;
        position: absolute;
        background-color: #d1d1d1;
    }
}