
// Background Colors
.bg-gray-900 { background-color: $gray-900 !important; }
.bg-gray-800 { background-color: $gray-800 !important; }
.bg-gray-700 { background-color: $gray-700 !important; }
.bg-gray-600 { background-color: $gray-600 !important; }
.bg-gray-500 { background-color: $gray-500 !important; }
.bg-gray-400 { background-color: $gray-400 !important; }
.bg-gray-300 { background-color: $gray-300 !important; }
.bg-gray-200 { background-color: $gray-200 !important; }
.bg-gray-100 { background-color: $gray-100 !important; }

.bg-ui-02 { background-color: $color-ui-02 !important; }
.bg-ui-03 { background-color: $color-ui-03 !important; }

.bg-pink { background-color: $pink !important; }
.bg-purple { background-color: $purple !important; }
.bg-indigo { background-color: $indigo !important; }
.bg-teal { background-color: $teal !important; }
.bg-orange { background-color: $orange; }

.bg-twitter { background-color: #1c96e9; }
.bg-amazon {
  background-color: #f79400;
  color: #222e3d;
}

// Divider
.divider {
  position: relative;
  display: flex;
  align-items: center;
  margin: 15px 0;

  &::before,
  &::after {
    content: '';
    flex: 1;
    height: 1px;
    background-color: $border-color;
  }

  span {
    padding: 0 10px;
    font-size: 12px;
    color: $secondary;
  }
}

.divider-start {
  &::before { display: none; }
  span { padding-left: 0; }
}

.divider-end {
  &::after { display: none; }
  span { padding-right: 0; }
}

.divider-vertical {
  margin: 0 15px;
  flex-direction: column;

  &::before,
  &::after {
    height: 100%;
    width: 1px;
  }

  span { padding: 5px 0; }
}

.divider-top {
  &::before { display: none; }
  span { padding: 0 0 5px; }
}

.divider-bottom {
  &::after { display: none; }
  span { padding: 5px 0 0; }
}

// Margins
.mt-10-f { margin-top: 10px !important; }
.me--10-f { margin-right: -10px !important; }

// Heights
.ht-auto { height: auto !important; }
.ht-3 { height: 3px !important; }
.ht-5 { height: 5px !important; }
.ht-8 { height: 8px !important; }
.ht-10 { height: 10px !important; }
.ht-15 { height: 15px !important; }
.ht-18 { height: 18px !important; }
.ht-20 { height: 20px !important; }
.ht-22 { height: 22px !important; }
.ht-30 { height: 30px !important; }
.ht-35 { height: 35px !important; }
.ht-40 { height: 40px !important; }
.ht-48 { height: 48px !important; }
.ht-50 { height: 50px !important; }
.ht-80 { height: 80px !important; }
.ht-90 { height: 90px !important; }
.ht-100 { height: 100px !important; }
.ht-150 { height: 150px !important; }
.ht-200 { height: 200px !important; }
.ht-250 { height: 250px !important; }
.ht-300 { height: 300px !important; }
.ht-350 { height: 350px !important; }

// Heights
.wt-auto { width: auto !important; }
.wt-3 { width: 3px !important; }
.wt-5 { width: 5px !important; }
.wt-8 { width: 8px !important; }
.wt-10 { width: 10px !important; }
.wt-15 { width: 15px !important; }
.wt-18 { width: 18px !important; }
.wt-20 { width: 20px !important; }
.wt-22 { width: 22px !important; }
.wt-30 { width: 30px !important; }
.wt-35 { width: 35px !important; }
.wt-40 { width: 40px !important; }
.wt-48 { width: 48px !important; }
.wt-50 { width: 50px !important; }
.wt-60 { width: 60px !important; }
.wt-70 { width: 70px !important; }
.wt-80 { width: 80px !important; }
.wt-90 { width: 90px !important; }
.wt-100 { width: 100px !important; }
.wt-120 { width: 120px !important; }
.wt-150 { width: 150px !important; }
.wt-200 { width: 200px !important; }
.wt-250 { width: 250px !important; }
.wt-300 { width: 300px !important; }
.wt-350 { width: 350px !important; }
.wt-400 { width: 400px !important; }
.wt-500 { width: 500px !important; }

// Widths
.w-0 { width: 0 !important; }
.w-5 { width: 5% !important; }
.w-10 { width: 10% !important; }
.w-15 { width: 15% !important; }
.w-15 { width: 15% !important; }
.w-17 { width: 17% !important; }
.w-20 { width: 20% !important; }
.w-30 { width: 30% !important; }
.w-32 { width: 32% !important; }
.w-35 { width: 35% !important; }
.w-40 { width: 40% !important; }
.w-45 { width: 45% !important; }
.w-50 { width: 50% !important; }
.w-60 { width: 60% !important; }
.w-70 { width: 70% !important; }
.w-80 { width: 80% !important; }
.w-85 { width: 85% !important; }
.w-100 { width: 100% !important; }
.w-200 { width: 200px; }

.mw-200 { max-width: 200px; }

// Line Heights
.lh-normal { line-height: normal !important; }
.lh-1 { line-height: 1 !important; }
.lh-3 { line-height: 1.3 !important; }
.lh-4 { line-height: 1.4 !important; }
.lh-5 { line-height: 1.5 !important; }

// Typography
.fs-sm { font-size: $font-size-sm; }
.fs-sm-normal { @include media-breakpoint-up(sm) { font-size: $font-size-base; } }
.fs-xs { font-size: $font-size-xs; }

.fs-10 { font-size: 10px !important; }
.fs-11 { font-size: 11px !important; }
.fs-12 { font-size: 12px !important; }
.fs-14 { font-size: 14px !important; }
.fs-15 { font-size: 15px !important; }
.fs-16 { font-size: 16px !important; }
.fs-18 { font-size: 18px !important; }
.fs-20 { font-size: 20px !important; }
.fs-24 { font-size: 24px !important; }
.fs-28 { font-size: 28px !important; }
.fs-30 { font-size: 30px !important; }
.fs-32 { font-size: 32px !important; }
.fs-36 { font-size: 36px !important; }
.fs-40 { font-size: 40px !important; }
.fs-44 { font-size: 44px !important; }
.fs-48 { font-size: 48px !important; }

.fw-thin { font-weight: 200 !important; }
.fw-light { font-weight: 300 !important; }
.fw-normal { font-weight: 400 !important; }
.fw-medium { font-weight: $font-weight-medium !important; }
.fw-semibold { font-weight: $font-weight-semibold !important; }

.ff-sans { font-family: $font-family-sans-serif; }
.ff-secondary { font-family: $ff-secondary; }
.ff-numerals { font-family: $ff-numerals; }

.ls--1 { letter-spacing: -1px; }
.ls--2 { letter-spacing: -2px; }

.text-primary-dark { color: darken($primary, 35%); }
.text-ui-02 { color: $color-ui-02; }

.ti--1 { text-indent: -1px; }
.ti--2 { text-indent: -2px; }
.ti--3 { text-indent: -3px; }


@media only screen and (max-width: 768px) {
  .w-40,
  .w-45,
  .w-50,
  .w-55 {
    width: 80% !important;
  }
}

@media only screen and (max-width: 480px) {
  .w-30,
  .w-35,
  .w-40,
  .w-45,
  .w-50,
  .w-55,
  .w-60,
  .w-70 {
    width: 95% !important;
  }
}