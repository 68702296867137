

.bg-lear-color {
    position: relative;
    &::after {
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        background-color: rgb(0 0 0 / 60%);
    }
    .account-logo {
        top: 20px;
        right: 20px;
        z-index: 2;
        position: absolute;
        img {
            width: 80px;
        }
    }
    .account-right-backround {
        width: 100%;
        height: 100%;
    }
}
